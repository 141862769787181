import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import TagLabel from 'src/components/atoms/TagLabel/TagLabel';
import Heading from 'src/components/shared/Heading/Heading';
import { mapSlugToTheme, sanitizeEncodedChar } from 'src/utils';

import styles from './ProjectSidebar.module.scss';

interface IProjectSidebarProps {
  className?: string;
  pageContext: any;
}

const ProjectSidebar: FunctionComponent<IProjectSidebarProps> = ({
  pageContext = {},
  className,
}) => {
  const { acf } = pageContext;
  return (
    <aside className={classNames(styles.projectSidebar, className)}>
      {pageContext.project_services && (
        <>
          <Heading className={styles.projectSidebarHeading} priority={4}>
            Profile
          </Heading>

          <p className={styles.projectSidebarCopy}>
            Company:{' '}
            <span className="display-block text--bold">
              {sanitizeEncodedChar(pageContext.title)}
            </span>
          </p>

          {acf.website && (
            <p className={styles.projectSidebarCopy}>
              Website:{' '}
              <a href={acf.website} className="display-block text--bold">
                {acf.website}
              </a>
            </p>
          )}

          <p
            className={classNames(
              styles.projectSidebarCopy,
              styles.projectSidebarCopyServices
            )}
          >
            Service{pageContext.project_services.length === 1 ? '' : 's'}{' '}
            carried out:
          </p>

          <div className={styles.projectHeroTagLabels}>
            {pageContext.project_services.map((project: any) => {
              const { name, id, slug } = project;
              if (slug !== 'featured') {
                return (
                  <TagLabel
                    className={styles.projectHeroTagLabel}
                    key={id}
                    link={`/project/service/${slug}`}
                    theme={mapSlugToTheme(slug)}
                    text={name}
                  />
                );
              }

              return null;
            })}
          </div>
        </>
      )}
    </aside>
  );
};

export default ProjectSidebar;
