import { Link } from 'gatsby';
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Container from 'src/components/grid/Container/Container';
import Flex from 'src/components/grid/Flex/Flex';
import Row from 'src/components/grid/Row/Row';
import Section from 'src/components/grid/Section/Section';
import Button from 'src/components/molecules/Buttons/Button';
import CtaBanner from 'src/components/organisms/CtaBanner/CtaBanner';
import FeaturedProjects from 'src/components/organisms/FeaturedProjects/FeaturedProjects';
import ProjectHero from 'src/components/organisms/ProjectHero/ProjectHero';
import TechnologyBanner from 'src/components/organisms/TechnologyBanner/TechnologyBanner';
import Heading from 'src/components/shared/Heading/Heading';
import ProjectSidebar from 'src/components/shared/Sidebar/ProjectSidebar/ProjectSidebar';
import WYSIWYG from 'src/components/shared/WYSIWYG/WYSIWYG';
import CoreLayout from 'src/layouts/CoreLayout/CoreLayout';
import { sanitizeEncodedChar } from 'src/utils';
import { IMenuOpen, IPageContext } from 'src/utils/interfaces';
import styles from './ProjectTemplate.module.scss';

export class UnconnectedProjectTemplate extends PureComponent<IPageContext> {
  render() {
    const { pageContext, location } = this.props;
    const { acf = {} } = pageContext;
    const pageTitle = sanitizeEncodedChar(pageContext.title);

    const setInnerHTML = () => {
      if (pageContext.content) {
        return { __html: `${pageContext.content && pageContext.content}` };
      }

      return null;
    };

    const imageAttrs = {
      loading: 'lazy'
    };

    return (
      <>
        <CoreLayout
          title={pageTitle}
          location={location}
          description={
            pageContext.yoast_meta &&
            pageContext.yoast_meta.yoast_wpseo_metadesc
          }
          data-test="template-project"
          className={classNames([
            styles.project,
            {
              [`project__${pageContext.slug &&
                pageContext.slug.replace('_', '-')}`]: pageContext.slug,
            },
          ])}
        >
          <ProjectHero
            pageTheme={acf && acf.page_theme}
            pageContext={pageContext}
            location={location}
          />

          <Section className={classNames('py-0', styles.projectLogoWrapper)}>
            <Container fluid={false} className="position-relative">
              {acf.logo && (
                <img
                  className={styles.projectLogo}
                  src={acf.logo.source_url}
                  alt={`${pageContext.title} logo`}
                  width={acf.logo.media_details.width}
                  height={acf.logo.media_details.height}
                  {...imageAttrs}
                />
              )}
            </Container>
          </Section>

          <Section>
            <Container fluid={false}>
              <Row>
                <Flex colXl={3} colLg={4}>
                  <ProjectSidebar pageContext={pageContext} />
                </Flex>
                <Flex colXl={8} colLg={7} className="ml-auto">
                  <Heading
                    className={styles.projectHeading}
                    innerHTML={false}
                    priority={2}
                  >
                    What we did for {pageTitle}
                  </Heading>
                  <WYSIWYG dangerouslySetInnerHTML={setInnerHTML()}>
                    {!pageContext.content && (
                      <>
                        <p>
                          We carried out the following service
                          {pageContext.project_services.length === 1
                            ? ''
                            : 's'}{' '}
                          for{' '}
                          <span className="text--bold">
                            {pageContext.title}
                          </span>
                          :{' '}
                          {pageContext.project_services.map((project: any) => {
                            const { name, slug, id } = project;
                            if (slug !== 'featured') {
                              return (
                                <span
                                  key={id}
                                  className={styles.projectCopyService}
                                >
                                  <Link to={`/project/service/${slug}`}>
                                    {name}
                                  </Link>
                                </span>
                              );
                            }
                          })}
                        </p>
                      </>
                    )}
                  </WYSIWYG>

                  {acf.website && (
                    <Button
                      theme={'brand'}
                      caps
                      className={styles.projectButton}
                      link={acf.website}
                      behaviour={'anchor'}
                    >
                      Check out their website
                    </Button>
                  )}
                </Flex>
              </Row>
            </Container>
          </Section>

          <FeaturedProjects pageTheme={'tint-beta'} />

          <TechnologyBanner pageTheme={'tint-alpha'} />

          <CtaBanner />
        </CoreLayout>
      </>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = ({ core: { isMenuOpen } }: IMenuOpen) => ({
  isMenuOpen,
});

export default connect(
  mapStateToProps,
  null
)(UnconnectedProjectTemplate);
